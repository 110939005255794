html{
  height: auto;
  min-height: 100%;
}
.center-margin{
  margin-left: auto;
  margin-right: auto;
}
.error{
  color: #de0000;
  font-size: 14px;
  text-align: center;
}
.float-left{
  float: left;
}
.float-right{
  float: right;
}

body{
  background: url('/images/mindable-home-bg.png') 0% 0% / cover no-repeat;
  min-height: 100%;
  margin: 0;

  .header-login{
    font-size: 14px;
    padding-top: 4px;
  }

  .btn-dashboard-grey{
    background-color: #6b788a;
    color: #fff;
  }
  .border-radius-0{
    border-radius: 0;
  }
  #add-portal-btn{
    margin-top: 10px;
  }

  .landing-container.login-box{
    padding: 25px;
    background-color: #2b333e;
    color: #fff;
    border-radius: 5px;
    .logo-div{
      text-align: center;
      margin-top: 20px;
      .logo{
        width: 40px;
      }
    }
    form{
      width: 400px;
      margin-left: auto;
      margin-right: auto;

      .input-label{
        float: left;
        width: 100px;
      }
      input{
        width: 280px;
        float: left;
      }
      .row{
        margin-bottom: 10px;
      }
    }
    input{
      //max-width: 300px;
    }
    h3{
      font-size: 14px;
      text-align: center;
    }
  }

  .main-container{
    height: 600px;
    background-color: #000;
    div{
      font-weight: bold;
    }
  }

  header{
    padding: 10px 30px;
    background-color: #2b333e;
    width: 100%;
    color: #fff;
    box-shadow: 2px 0 2px #000;
    a, a:hover{
      text-decoration: none;
      color: #fff;
    }
    img{
      margin-right: 1px;
      width: 30px;
      margin-top: -9px;
    }
    .logo-text {
      font-size: 20px;
    }
  }
}
.landing-container-row{
  margin-top: 80px;
  .landing-container{
    width: 100%;
    background-color: #fff;
    min-height: 400px;
    border-radius: 10px;
    box-shadow: 3px 2px 6px #c5c5c5;
    padding-top: 30px;
    padding-bottom: 30px;
    h1{
      font-size: 30px;
      text-align: center;
    }
  }
  .landing-img-div {
    text-align: center;
    margin-top: -25px;

    img {
      width: 300px;
    }
  }
  .site-divs{
    padding: 40px 20px;
    max-width: 350px;

    a:hover{
      text-decoration: none;
    }
    .site-div{
      font-size: 14px;
      padding: 8px 15px;
      color: #fff;
      background-color: #2b333e;
      //max-width: 300px;
      text-decoration: none;
      &:hover{
        background-color: #212121;
        text-decoration: none;
        color: #fff;
      }
      a{

        color: #fff;
      }
      img{
        width: 25px;
        margin-right: 5px;
      }
      margin-bottom: 10px;
    }
  }
}
#add-new-portal-link, #edit-delete-new-portal-link{
  color: green;
  margin-left: 50px;
  text-decoration: none;
  font-size: 13px;
}
#add-portal-form, #edit-portal-form {
  .row {
    font-size: 14px;
    margin-bottom: 10px;

    .inline-check {
      margin-top: 3px;
    }

    .form-control{
      font-size: 14px;
    }
  }
  .modal-footer{
    button{
      font-size: 14px;
    }
  }
}

#add-portal-modal, #edit-portal-modal{
  .modal-content{
    margin-top: 100px;
    border-radius: 0;
    padding: 20px;
    padding-bottom: 10px;
  }
}

#add-portal-modal-content, #edit-portal-modal-content{
  color: #fff;
  background-color: #3e3e3e;
}

.portal-display-div{
  float: left; width: 80%; min-width: 200px;
}
.portal-action-div{
  float: left; width: 20%; min-width: 45px; padding: 8px 5px; font-size: 16px; display: none;
  a{
    color: red;
  }
  form{
    float: left;
    width: 15px;
    margin-left: 2px;
  }
  a.edit{
    color: dodgerblue;
  }
}

  @media only screen and (max-width: 998px) {
  .landing-container-row .landing-img-div img{
    max-width: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .landing-container-row .site-divs {
    max-width: 100%;
  }
}
